<!--
 * @Description: 培养计划详情
 * @Author: xiawenlong
 * @Date: 2021-09-27 09:35:29
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-09-27 15:26:59
-->
<template>
  <div class="add-course-by-plan-detail">
    <div class="header">
      <img class="cover" :src="detail.pic" />
      <div class="content">
        <div class="title">{{ detail.trainName }}</div>
        <div class="time">{{ detail.publishTime }}</div>
        <div class="post">{{ detail.post }}</div>
      </div>
    </div>
    <div class="main">
      <el-card class="box-card planInfo">
        <div slot="header" class="clearfix header">
          <span class="title">方案详情</span>
        </div>
        <div class="content">
          <img style="max-width:100%" :src="detail.explainStr" alt="" />
        </div>
      </el-card>
      <el-card class="box-card planMenu">
        <div slot="header" class="clearfix header">
          <span class="title">培训目录</span>
        </div>
        <div class="content">
          <div
            v-for="(item, index) in detail.relResps"
            :key="index"
            class="course-item"
            @click="toCourse(item.productId)"
          >
            <img :src="item.pic" alt="" class="cover" />
            <div class="content">
              <div class="name">
                <span>{{ item.name }}</span>
                <i :class="item.courseType == 0 ? 'lubo' : 'zhibo'">{{
                  item.courseType == 0 ? '录' : '直'
                }}</i>
              </div>
              <div class="teacher">讲师：{{ item.name }}</div>
              <div class="time">总课时：{{ item.timeCount }}分钟</div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { coursePlanDetail } from '@/api/class'
import to from 'await-to'
export default {
  name: 'AddCourseByPlanDetail',
  data() {
    return {
      detail: {},
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      const { trainPlanId } = this.$route.params
      const [res, err] = await to(coursePlanDetail({ trainPlanId }))
      if (err) return this.$message.warning(err.msg)
      this.detail = res.data
    },
    toCourse(id) {
      this.$router.push(`/course/shop/${id}`)
    },
  },
}
</script>
<style lang="scss" scoped>
.add-course-by-plan-detail {
  > .header {
    width: 100%;
    height: 186px;
    background: #ffffff;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    .cover {
      width: 282px;
      height: 145px;
      margin-right: 30px;
    }
    .title {
      line-height: 24px;
      font-size: 24px;
      color: #333333;
      margin-bottom: 13px;
    }
    .time {
      line-height: 14px;
      font-size: 14px;
      color: #666666;
      margin-bottom: 19px;
    }
    .post {
      font-size: 14px;
      color: #666666;
      line-height: 25px;
    }
  }
  > .main {
    display: flex;
    align-items: flex-start;
    .planInfo {
      width: 60%;
      margin-right: 20px !important;
      .content {
        text-align: center;
      }
    }
    .planMenu {
      width: 40%;
      .course-item {
        cursor: pointer;
        display: flex;
        margin-bottom: 23px;
        &:last-child {
          margin-bottom: 0;
        }
        .cover {
          width: 134px;
          height: 68px;
          border-radius: 4px;
          margin-right: 13px;
        }
        .name {
          margin-bottom: 7px;
          display: flex;
          align-items: center;
          margin-top: -4px;
          .span {
            font-size: 14px;
            color: #333333;
            margin-right: 12px;
          }
          i {
            font-style: normal;
            width: 22px;
            height: 22px;
            border-radius: 4px;
            font-size: 12px;
            text-align: center;
            color: #ffffff;
            line-height: 22px;
            margin-left: 12px;
            &.zhibo {
              background: linear-gradient(234deg, #5fd69c 10%, #4cc998 90%);
            }
            &.lubo {
              background: linear-gradient(234deg, #fc8a25 10%, #ffcf55 90%);
            }
          }
        }
        .teacher {
          font-size: 13px;
          color: #909399;
          line-height: 13px;
          margin-bottom: 16px;
        }
        .time {
          font-size: 13px;
          color: #909399;
          line-height: 13px;
        }
      }
    }
  }
}
</style>
